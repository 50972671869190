import moment from "moment-timezone";

import messsageSound from "../assets/message-received.mp3";
import {
  EVENTS,
  MESSAGES,
  EXCEPTION_MESSAGE,
  UNAVAILABLE_REASON,
  CHAT_CHANNEL,
} from "../constants";
import { agentEndedSession, getConversationData } from "./businessRules";
import { postChatExceptionsToKafkaTopic } from "./kafkaLogs";
import LiveWorkItemState from "@microsoft/omnichannel-chat-sdk/lib/core/LiveWorkItemState";
import { isCustomerMessage } from "@microsoft/omnichannel-chat-sdk";

export const messageNotification = new Audio(messsageSound);

export const getIdOfQuestionByName = (preChatQuestions, name) => {
  const id = preChatQuestions.body.find(
    (item) =>
      (item.type === "Input.Text" || item.type === "Input.ChoiceSet") &&
      JSON.parse(item.id).Name === name
  )?.id;
  return id;
};

export const clearSessionStore = (type) => {
  sessionStorage.removeItem("timed-out");
  sessionStorage.removeItem("agent-ended-session");
  if (CHAT_CHANNEL.EXTERNAL === type) {
    localStorage.removeItem("chatBotEnabled");
    localStorage.removeItem("chatBotTimestamp");
    sessionStorage.removeItem("MS365ChatSession");
    sessionStorage.removeItem("MS365ChatPreChatSurvey");
  } else if (CHAT_CHANNEL.ENTERPRISE === type) {
    sessionStorage.removeItem("MS365EnterpriseChatSession");
    sessionStorage.removeItem("MS365EnterpriseChatPreChatSurvey");
  } else if (CHAT_CHANNEL.INTERNAL === type) {
    sessionStorage.removeItem("MS365ChatSession");
    sessionStorage.removeItem("MS365ChatPreChatSurvey");
    sessionStorage.removeItem("MS365CurrentChannel");
  }
};

export const cleanFieldsAndValidations = (
  preChatSurveyResponses,
  salesLocation,
  type
) => {
  if (type === CHAT_CHANNEL.EXTERNAL) {
    preChatSurveyResponses.value.usi = "";
    preChatSurveyResponses.value.name = "";
    preChatSurveyResponses.value.email = "";
    preChatSurveyResponses.value.phone = "";
    preChatSurveyResponses.value.uuidt = "GUEST";
    preChatSurveyResponses.value.uuidr = "GUEST";
    preChatSurveyResponses.value.source = window.location.pathname;
    preChatSurveyResponses.value.channel_id = salesLocation ? "Sales" : "";
  } else if (type === CHAT_CHANNEL.ENTERPRISE) {
    preChatSurveyResponses.value.name = "";
    preChatSurveyResponses.value.email = "";
    preChatSurveyResponses.value.phone = "";
    preChatSurveyResponses.value.companyName = "";
    preChatSurveyResponses.value.subject = "";
  }
};

export const addAgentAvailabilityToChannel = (
  _item = {},
  utcTimeNow = "",
  offset = 1
) => {
  let item = { ..._item };
  let message = "";
  let agentAvailKey =
    "agent_available" in item ? "agent_available" : "agentAvailable";
  if (item[agentAvailKey] === undefined) {
    agentAvailKey = "agentAvailability";
  }
  const operationHoursKey =
    "operation_hours" in item ? "operation_hours" : "operatingHours";
  const conditionOne = item[agentAvailKey]
    ? item[agentAvailKey] === false
    : true;
  let conditionTwo =
    item[operationHoursKey] === null || item[operationHoursKey] === "";
  if (!conditionTwo) {
    const operatingHours = item[operationHoursKey][0] ?? {};
    const { Start, End } = operatingHours;
    if (Start && End) {
      let currentTime = moment().tz("US/Pacific");
      if (utcTimeNow) {
        currentTime = moment(utcTimeNow)
          .add(offset / (1000 * 60), "minutes")
          .tz("US/Pacific");
      }
      const isAvailable = moment(currentTime).isBetween(
        moment(Start),
        moment(End)
      );
      conditionTwo = !isAvailable;
    } else {
      conditionTwo = true;
    }
  }
  if (conditionTwo) {
    message =
      item?.operation_hours_human ||
      item?.operationHoursHuman ||
      "Please contact us during business hours.";
  } else if (conditionOne) {
    message = "No Agents Available, Try after sometime.";
  }
  item.message = message;
  item.isUnAvailable = conditionOne || conditionTwo || false;
  item.reason = conditionTwo
    ? UNAVAILABLE_REASON.OOH
    : UNAVAILABLE_REASON.NO_AGENTS;
  return item;
};

export const extractDataFromMyAccount = () => {
  const localStorage = window.localStorage;
  let userInfo = null;
  let tokenResponse = null;
  try {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
    tokenResponse = JSON.parse(localStorage.getItem("tokenResponse"));
  } catch (e) {
    console.log(e);
  } finally {
    return { userInfo, tokenResponse };
  }
};

export const buildOOHMessage = (data) => {
  let humanMessage = "";
  if (data && data?.availability && data?.availability.length > 0) {
    humanMessage = "\nAvailability Timings:";
    data.availability.forEach((item) => {
      humanMessage = `${humanMessage}\n${item.from} - ${item.to} from ${item.time}`;
    });
  }
  return humanMessage;
};

export const loadChannelDetails = async (url, appId) => {
  try {
    let res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        app_id: appId,
      }),
    });
    res = await res.json();
    return {
      success: true,
      data: res?.queues[0],
    };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const typingEvent = (isTyping) => {
  isTyping.value = true;
  setTimeout(() => {
    isTyping.value = false;
  }, 1000);
};

export const onNewMessage = async (
  chatId,
  message,
  agentName,
  chatMessages,
  sendingMessage,
  showChatWidget,
  waiting_to_start,
  audioPlayEnabled,
  chatBotTyping,
  inputDisabledByAdaptiveCard,
  type,
  savedMessages,
  convId,
  chatSDK,
  surveyUrl,
  channelOptions,
  confirmCloseChat,
  showPostChatSurvey,
  preChatSurveyResponses,
  conversationEndByZoey,
  trackEndEventAlreadyCalled
) => {
  if (message !== " ") {
    if (chatBotTyping) {
      chatBotTyping.value = false;
    }
    if (!isCustomerMessage(message) && type === CHAT_CHANNEL.INTERNAL) {
      agentName.value = message.sender.displayName;
      chatId.value = chatSDK?.chatToken?.chatId;
    }
    if (
      chatMessages.value.length === 1 &&
      chatMessages.value[0].id === "00000000-0000-0000-0000-000000000000"
    ) {
      if (type !== CHAT_CHANNEL.INTERNAL) {
        chatMessages.value = [];
      }
      sendingMessage.value = false;
    }
    if (waiting_to_start.value !== null) {
      clearTimeout(waiting_to_start.value);
      waiting_to_start.value = null;
      if (type !== CHAT_CHANNEL.INTERNAL) {
        chatMessages.value = [];
      }
      sendingMessage.value = false;
    }
    showChatWidget.value = true;
    if (
      chatMessages.value.length === 1 &&
      message.content === chatMessages.value[0].content
    ) {
      return;
    }
    if (
      type === CHAT_CHANNEL.EXTERNAL &&
      savedMessages &&
      savedMessages.value !== null
    ) {
      savedMessages.value = [message, ...chatMessages.value];
    }
    if (message.content.includes("Zoey has ended the conversation.")) {
      conversationEndByZoey.value = true;
    }
    if (message.content.startsWith("#NDS_WIDGET_ACTION")) {
      const action = message.content.split("=")[1];
      switch (action) {
        case "loading()":
          if (
            inputDisabledByAdaptiveCard &&
            !inputDisabledByAdaptiveCard.value
          ) {
            if (chatBotTyping) chatBotTyping.value = true;
          }
          break;
        case "disableInput()":
          if (chatBotTyping) chatBotTyping.value = true;
          break;
        case "enableInput()":
          if (chatBotTyping) chatBotTyping.value = false;
          break;
        case "agentEndedSession()":
          trackEndEventAlreadyCalled.value = true;
          await agentEndedSession(
            CHAT_CHANNEL.EXTERNAL,
            convId,
            chatSDK,
            surveyUrl,
            chatMessages,
            sendingMessage,
            channelOptions,
            confirmCloseChat,
            showPostChatSurvey,
            preChatSurveyResponses
          );
          clearSessionStore(CHAT_CHANNEL.EXTERNAL);
          break;
        default:
          if (action.startsWith("CITATIONS(")) {
            // Handle CITATIONS action
          } else {
            console.log("Unknown action: " + action);
          }
          break;
      }
    }

    if (
      audioPlayEnabled.value &&
      !message.content.startsWith("#NDS_WIDGET_ACTION")
    ) {
      messageNotification.play();
    }
    chatMessages.value = [message, ...chatMessages.value];
    scrollToBottom();
  }
};

export const fillingPhone = (phone) => {
  let value = phone;
  value = value.replace(/\D/g, "");
  if (value.length <= 3) {
    phone_input_error.value = "";
  } else if (value.length <= 6) {
    value = "(" + value.substring(0, 3) + ") " + value.substring(3);
  } else {
    value =
      "(" +
      value.substring(0, 3) +
      ") " +
      value.substring(3, 6) +
      "-" +
      value.substring(6, 10);
  }
  return value;
};

export const validateName = (event) => {
  var charCode = event.keyCode;
  if (
    (charCode >= 97 && charCode <= 122) ||
    (charCode >= 65 && charCode <= 90) ||
    charCode === 32
  ) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const buildDataToStartChat = (
  preChatQuestions,
  preChatSurveyResponses
) => {
  const nameKey = getIdOfQuestionByName(preChatQuestions, "Name");
  const emailKey = getIdOfQuestionByName(preChatQuestions, "Email");
  const phoneKey = getIdOfQuestionByName(preChatQuestions, "Phone");
  const sourceKey = getIdOfQuestionByName(preChatQuestions, "Source");
  const preChatResponse = {
    Type: "InputSubmit",
  };
  const chatContext = {
    RequestType: { value: preChatSurveyResponses.channel_id },
    usi: {
      value: preChatSurveyResponses.usi ? preChatSurveyResponses.usi : "",
    },
    uuidt: {
      value: preChatSurveyResponses.uuidt ? preChatSurveyResponses.uuidt : "",
    },
    uuidr: {
      value: preChatSurveyResponses.uuidr ? preChatSurveyResponses.uuidr : "",
    },
  };
  preChatResponse[nameKey] = preChatSurveyResponses.name;
  preChatResponse[emailKey] = preChatSurveyResponses.email;
  preChatResponse[phoneKey] = preChatSurveyResponses.phone.replace(
    /[^0-9]/g,
    ""
  );
  preChatResponse[sourceKey] = window.location.href || "Unknown";
  return { preChatResponse, chatContext };
};

export const extractDataFromIDC = () => {
  const localStorage = window.localStorage;
  let user = null;
  try {
    user = JSON.parse(localStorage.getItem("Authentication"));
  } catch (e) {
    console.log(e);
  }
  return user;
};

export const isJsonMessage = (msg) => {
  try {
    const val = JSON.parse(msg);
    return "value" in val;
  } catch (e) {
    return false;
  }
};

export const printJsonMessage = (msg) => {
  try {
    const val = JSON.parse(msg).value;
    let toRet = "";
    for (let key in val) {
      if (!key.startsWith("action")) {
        toRet += `${key} : ${val[key]}<br>`;
      }
    }
    return toRet;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getName = (fullName) => {
  fullName = fullName.trim();
  const nameChunks = fullName.split(/\s+/);
  if (nameChunks.length === 1) {
    return { firstName: fullName, lastName: "" };
  } else if (nameChunks.length > 2) {
    return {
      firstName: nameChunks.slice(0, nameChunks.length - 1).join(" "),
      lastName: nameChunks[nameChunks.length - 1],
    };
  } else {
    return { firstName: nameChunks[0], lastName: nameChunks[1] };
  }
};

export const initializeAudio = (
  unmute = false,
  audioPlayEnabled,
  userMuted
) => {
  if (!audioPlayEnabled.value) {
    messageNotification.play().then(() => {
      messageNotification.pause();
      messageNotification.currentTime = 0;
      audioPlayEnabled.value = true;
    });
  }
  if (unmute) {
    userMuted.value = false;
  }
  if (!audioPlayEnabled.value) {
    userMuted.value = true;
  }
};

export const scrollToBottom = () => {
  setTimeout(() => {
    let scrollContainer = document.getElementById("scrollContainer");
    scrollContainer
      ?.scrollTo(0, scrollContainer.scrollHeight)
      ?.scrollIntoView({ behavior: "smooth" });
    document.getElementById("chatInputBox")?.focus();
  }, 500);
};

export const sendUserMessage = async (
  chatSDK,
  message = "",
  metadata = null,
  userMuted,
  audioPlayEnabled,
  messageToSend,
  chatMessages,
  sendingMessage,
  convId,
  sendMessageError,
  preChatSurveyResponses,
  channelOptions,
  type,
  showChatBot
) => {
  if (messageToSend.value.length === 0 && message.length === 0) {
    return;
  }
  const { conversationDetails } = await getConversationData();
  messageToSend.value = messageToSend.value.trim();
  message = message.trim();
  if (messageToSend.value === " ") {
    sendMessageError.value = "Please enter a valid message";
    return;
  } else {
    sendMessageError.value = "";
  }
  try {
    if (messageToSend.value.length === 0 && message.length === 0) {
      return;
    }
    const _message = message || messageToSend.value;
    initializeAudio(false, audioPlayEnabled, userMuted);
    sendingMessage.value = true;
    if (conversationDetails.state === LiveWorkItemState.Closed) {
      throw new Error(MESSAGES.CONVERSATION_CLOSED);
    }
    const toSend = metadata
      ? { content: _message, metadata }
      : { content: _message };
    await chatSDK.sendMessage(toSend);
    if (showChatBot) {
      localStorage.setItem(
        "chatBotTimestamp",
        JSON.stringify(new Date().toString())
      );
    }
    chatMessages.value = [
      {
        id: "00000000-0000-0000-0000-000000000000",
        content: _message,
        sender: {
          displayName: "Customer",
        },
        metadata: metadata,
        timestamp: new Date().toISOString(),
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    messageToSend.value = "";
    scrollToBottom();
    sendMessageError.value = "";
  } catch (e) {
    console.log(e);
    sendMessageError.value = MESSAGES.CONVERSATION_CLOSED;
    try {
      await postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        type,
        preChatSurveyResponses.value,
        channelOptions.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.SEND_MESSAGE,
        conversationDetails
      );
    } catch (e) {
      console.log(e);
    }
    sendingMessage.value = false;
  }
};
