<script setup>
import { onMounted, onUnmounted, ref, defineProps, computed } from "vue";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";

import ChatEnd from "./components/ChatEnd.vue";
import ChatFooter from "./components/ChatFooter.vue";
import ChatHeader from "./components/ChatHeader.vue";
import AllMessages from "./components/AllMessages.vue";
import ChatActivator from "./components/ChatActivator.vue";
import { ifExistingSession } from "./utils/existingSession";
import ConfirmEndChat from "./components/ConfirmEndChat.vue";
import PreChatScreens from "./components/PreChatScreens.vue";
import { onOffline, onOnline } from "./utils/internetDisconnectivity";
import { handleFileDownlaod, handleFileUpload } from "./utils/handleFiles";
import {
  postChatUserActionsToKafkaTopic,
  postChatExceptionsToKafkaTopic,
} from "./utils/kafkaLogs";
import {
  getHashKey,
  runSetupBeforeChat,
  saveCustomerSession,
  generateFingerPrint,
} from "./utils/loginFlow";
import {
  EVENTS,
  CHAT_CHANNEL,
  REFRESH_INTERVAL,
  EXCEPTION_MESSAGE,
  INITIALIZATION_ERROR,
  OUTSIDE_OPERATING_HOURS,
} from "./constants";
import {
  getName,
  fillingPhone,
  scrollToBottom,
  initializeAudio,
  sendUserMessage,
  clearSessionStore,
  buildDataToStartChat,
  getIdOfQuestionByName,
  typingEvent,
  onNewMessage,
} from "./utils/commonRules";
import {
  clearSurvey,
  updateQueues,
  handleEndChat,
  handleCleanup,
  agentEndedSession,
  handleStartNewChat,
  loadChannelOptions,
  handleCancelEndChat,
  getConversationData,
  checkAndSyncMessages,
  getChatAllowedOnPaths,
  handleCloseChatWindow,
  requestEmailTranscript,
  startChatIfUserLoggedIn,
} from "./utils/businessRules";

const props = defineProps({
  showChatBot: Boolean,
  isInternal: {
    type: Boolean,
    default: false,
  },
});

const omnichannelConfig = {
  orgUrl: import.meta.env.VITE_CHATBOT_ORG_URL,
  orgId: import.meta.env.VITE_ORG_ID,
  widgetId: import.meta.env.VITE_CHATBOT_APP_ID,
};

const chatSDKConfig = {
  telemetry: {
    disable: true,
  },
};

const offset = ref(0);
const convId = ref("");
const hashKey = ref("");
const chatId = ref(null);
const isTyping = ref(false);
const allowChat = ref(true);
const fingerPrint = ref("");
const agentName = ref(null);
const surveyUrl = ref(null);
const isLoading = ref(true);
const userMuted = ref(false);
const chatMessages = ref([]);
const labelToIdMap = ref({});
const messageToSend = ref("");
const systemMessage = ref("");
const closedExtra = ref(false);
const savedMessages = ref(null);
const emailTranscript = ref("");
const chatBotTyping = ref(false);
const preChatQuestions = ref([]);
const isNewCustomer = ref(false);
const channelOptions = ref(null);
const sendMessageError = ref("");
const offlineTimeout = ref(null);
const endChatLoading = ref(false);
const showChatWidget = ref(false);
const initializeError = ref(null);
const sendingMessage = ref(false);
const transcriptSent = ref(false);
const inactivityTimeout = ref("");
const waiting_to_start = ref(null);
const isZiplyCustomer = ref(false);
const confirmCloseChat = ref(false);
const audioPlayEnabled = ref(false);
const initiatingSurvey = ref(false);
const transcriptSentFailed = ref("");
const transcriptSending = ref(false);
const isExistingCustomer = ref(false);
const showPostChatSurvey = ref(false);
const showQualtricsSurvey = ref(false);
const salesAgentsAvailable = ref(true);
const foundCustomerSession = ref(false);
const confirmDecisionState = ref(false);
const conversationEndByZoey = ref(false);
const trackEndEventAlreadyCalled = ref(false);
const inputDisabledByAdaptiveCard = ref(false);

const chatSDK = new OmnichannelChatSDK(omnichannelConfig, chatSDKConfig);
const salesLocation = window.location.pathname.includes("sales") ? true : false;
const preChatSurveyResponses = ref({
  usi: "",
  name: "",
  email: "",
  phone: "",
  uuidt: "GUEST",
  uuidr: "GUEST",
  source: window.location.pathname,
  channel_id: salesLocation ? "Sales" : "",
});

const isMobileApp = localStorage?.getItem("mobile");

const customerChatInterval = setInterval(() => {
  let queues = channelOptions?.value?.queues;
  offset.value = offset.value + REFRESH_INTERVAL;
  const utc_now = channelOptions?.value?.utc_now;
  const updatedQueues = updateQueues(
    queues,
    utc_now,
    offset.value,
    salesLocation,
    chatSDK,
    salesAgentsAvailable,
    preChatSurveyResponses
  );
  channelOptions.value = {
    utc_now: utc_now,
    queues: updatedQueues,
  };
}, REFRESH_INTERVAL);

onMounted(async () => {
  isLoading.value = true;
  fingerPrint.value = await generateFingerPrint();
  if (localStorage?.getItem("mobile")) {
    import("./styles/mobile.css");
  }
  getChatAllowedOnPaths(isMobileApp, allowChat);
  if (!allowChat.value) {
    isLoading.value = false;
    return;
  }
  await loadChannelOptions(
    false,
    offset,
    salesLocation,
    chatSDK,
    salesAgentsAvailable,
    preChatSurveyResponses,
    channelOptions
  );
  await chatSDK.initialize();
  hashKey.value = await getHashKey();
  preChatQuestions.value = await chatSDK.getPreChatSurvey();
  const sessionAlreadyExists = await ifExistingSession(
    CHAT_CHANNEL.EXTERNAL,
    convId,
    endChat,
    chatSDK,
    isLoading,
    surveyUrl,
    closedExtra,
    chatMessages,
    showChatWidget,
    sendingMessage,
    channelOptions,
    emailTranscript,
    confirmCloseChat,
    showPostChatSurvey,
    initializeChatEvents,
    preChatSurveyResponses,
    chatBotTyping,
    inputDisabledByAdaptiveCard,
    savedMessages,
    true
  );
  if (sessionAlreadyExists) {
    return;
  } else {
    await runSetupBeforeChat(
      hashKey,
      fingerPrint,
      isNewCustomer,
      isZiplyCustomer,
      foundCustomerSession,
      preChatSurveyResponses
    );
  }
  isLoading.value = false;
});

onUnmounted(() => {
  clearInterval(customerChatInterval);
});

const existingCustomerFlow = () => {
  isZiplyCustomer.value = true;
  isExistingCustomer.value = true;
  isNewCustomer.value = false;
  foundCustomerSession.value = true;
};

const newCustomer = () => {
  isNewCustomer.value = true;
  isExistingCustomer.value = false;
  isZiplyCustomer.value = false;
  foundCustomerSession.value = true;
  preChatSurveyResponses.value.channel_id = "";
  preChatSurveyResponses.value.name = "";
  preChatSurveyResponses.value.email = "";
  preChatSurveyResponses.value.phone = "";
  preChatSurveyResponses.value.usi = "";
  preChatSurveyResponses.value.uuidr = "GUEST";
  preChatSurveyResponses.value.uuidt = "GUEST";
};

const toggleChat = async () => {
  initializeAudio(false, audioPlayEnabled, userMuted);
  if (confirmCloseChat.value) return;
  showChatWidget.value = !showChatWidget.value;
  if (showChatWidget.value) {
    closedExtra.value = true;
    if (chatMessages.value.length > 0) {
      scrollToBottom();
    }
  }
};

const disableChatOnAdaptiveCard = computed(() => {
  if (chatMessages.value.length > 0) {
    if (
      typeof chatMessages?.value[0]?.content === "string" &&
      chatMessages.value[0]?.content.indexOf("attachments") !== -1
    ) {
      const parsedContent = JSON.parse(chatMessages.value[0]?.content);
      return (
        parsedContent.attachments?.length > 0 &&
        parsedContent.attachments[0].contentType ===
          "application/vnd.microsoft.card.adaptive"
      );
    }
  }
  return false;
});

const initializeChatEvents = async () => {
  await chatSDK.onNewMessage(async (message) => {
    onNewMessage(
      chatId,
      message,
      agentName,
      chatMessages,
      sendingMessage,
      showChatWidget,
      waiting_to_start,
      audioPlayEnabled,
      chatBotTyping,
      inputDisabledByAdaptiveCard,
      CHAT_CHANNEL.EXTERNAL,
      savedMessages,
      convId,
      chatSDK,
      surveyUrl,
      channelOptions,
      confirmCloseChat,
      showPostChatSurvey,
      preChatSurveyResponses,
      conversationEndByZoey,
      trackEndEventAlreadyCalled
    );
  });
  await chatSDK.onTypingEvent(() => {
    typingEvent(isTyping);
  });
  await chatSDK.onAgentEndSession(async () => {
    if (trackEndEventAlreadyCalled.value === false) {
      agentEndedSession(
        CHAT_CHANNEL.EXTERNAL,
        convId,
        chatSDK,
        surveyUrl,
        chatMessages,
        sendingMessage,
        channelOptions,
        confirmCloseChat,
        showPostChatSurvey,
        preChatSurveyResponses
      );
      clearSessionStore(CHAT_CHANNEL.EXTERNAL);
    }
  });
};

const chatAction = async () => {
  console.warn("CHAT INITIATED");
  if (salesLocation === true && !showChatWidget.value && !isLoading.value) {
    if (chatSDK.isInitialized && chatSDK?.chatToken?.chatId) {
      toggleChat();
    } else {
      const channel_id = "Sales";
      const _channelOptions = await loadChannelOptions(
        true,
        offset,
        salesLocation,
        chatSDK,
        salesAgentsAvailable,
        preChatSurveyResponses,
        channelOptions
      );
      const selectedChannel = _channelOptions?.queues.find(
        (item) => item.name === channel_id
      );
      if (selectedChannel?.isUnAvailable) {
        initializeError.value = selectedChannel.message;
        return;
      } else {
        initializeError.value = null;
        startChat();
      }
    }
  } else if (
    (window.location.pathname.includes("/myaccount") ||
      window.origin.includes("ziplyfiber.com")) &&
    localStorage.getItem("userInfo") !== null
  ) {
    startChatIfUserLoggedIn(
      startChat,
      showChatWidget,
      preChatQuestions,
      preChatSurveyResponses
    );
  } else if (!isLoading.value) {
    toggleChat();
  }
};

const startChat = async (preChatResponse, customContext) => {
  // API Call will reset the preChatSurveyResponses.channel_id to '' if the selected channel is unavailable
  if (isNewCustomer.value === true && isZiplyCustomer.value === false) {
    const channel_id = preChatSurveyResponses.value.channel_id;
    const _channelOptions = await loadChannelOptions(
      true,
      offset,
      salesLocation,
      chatSDK,
      salesAgentsAvailable,
      preChatSurveyResponses,
      channelOptions
    );
    // Setting back preChatSurveyResponses.channel_id to channel_id
    preChatSurveyResponses.value.channel_id = channel_id;
    const selectedChannel = _channelOptions?.queues.find(
      (item) => item.name === channel_id
    );
    if (selectedChannel?.isUnAvailable) {
      initializeError.value = selectedChannel.message;
      if (salesLocation) {
        toggleChat();
      }
      return;
    } else {
      initializeError.value = null;
    }
  }
  transcriptSent.value = false;
  if (!channelOptions.value?.utc_now) {
    return;
  }
  if (isNewCustomer.value) {
    const { firstName, lastName } = getName(preChatSurveyResponses.value.name);
    const session = {
      uuidf: fingerPrint.value,
      firstName,
      lastName,
      email: preChatSurveyResponses.value.email,
      phone: preChatSurveyResponses.value.phone,
      usi: preChatSurveyResponses.value.usi,
    };
    saveCustomerSession(session, hashKey.value);
  }
  if (salesLocation) {
    const sourceKey = getIdOfQuestionByName(preChatQuestions.value, "Source");
    customContext = {
      ...customContext,
      RequestType: { value: "Sales" },
    };
    preChatResponse = {
      ...preChatResponse,
      [sourceKey]: window?.location?.href || "Unknown",
      Type: "InputSubmit",
    };
  }
  try {
    initializeAudio(true, audioPlayEnabled, userMuted);
    isLoading.value = true;
    conversationEndByZoey.value = false;
    await chatSDK.startChat({
      preChatResponse: preChatResponse,
      customContext: customContext,
      sendDefaultInitContext: true,
    });
    console.warn("CHAT STARTED");
    localStorage.setItem("chatBotEnabled", "true");
    localStorage.setItem("chatBotTimestamp", new Date().toString());
    const chatContext = await chatSDK.getCurrentLiveChatContext();
    sessionStorage.setItem("MS365ChatSession", JSON.stringify(chatContext));
    sessionStorage.setItem(
      "MS365ChatPreChatSurvey",
      JSON.stringify(preChatSurveyResponses.value)
    );
    await initializeChatEvents();
    checkAndSyncMessages(
      chatMessages,
      sendingMessage,
      waiting_to_start,
      CHAT_CHANNEL.EXTERNAL,
      true,
      isNewCustomer
    );
    if (salesLocation) {
      showChatWidget.value = !showChatWidget.value;
    }
    setTimeout(async () => {
      const { conversationDetails } = await getConversationData(
        chatSDK,
        chatMessages
      );
      convId.value = conversationDetails.conversationId;
      if (convId.value !== "") {
        await postChatUserActionsToKafkaTopic(
          EVENTS.START,
          CHAT_CHANNEL.EXTERNAL,
          channelOptions.value,
          preChatSurveyResponses.value,
          convId.value,
          conversationDetails
        );
      }
    }, 10000);
    isLoading.value = false;
    initializeError.value = null;
  } catch (e) {
    if (e.message === "ConversationInitializationFailure") {
      initializeError.value = INITIALIZATION_ERROR;
    } else if (e.message === "WidgetUseOutsideOperatingHour") {
      initializeError.value = OUTSIDE_OPERATING_HOURS;
    }
    try {
      postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        CHAT_CHANNEL.EXTERNAL,
        preChatSurveyResponses.value,
        channelOptions.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.SEND_MESSAGE
      );
    } catch (e) {
      console.log(e);
    }
    isLoading.value = false;
    console.log(e);
    if (salesLocation === true) {
      toggleChat();
    }
  }
};

const sendMessage = async (message, metadata) => {
  sendUserMessage(
    chatSDK,
    message,
    metadata,
    userMuted,
    audioPlayEnabled,
    messageToSend,
    chatMessages,
    sendingMessage,
    convId,
    sendMessageError,
    preChatSurveyResponses,
    channelOptions,
    CHAT_CHANNEL.EXTERNAL,
    props.showChatBot
  );
};

const customerTyping = async (event) => {
  if (event.key === "Enter" && messageToSend.value.length > 0) {
    await sendMessage();
  }
};

const endChat = async (confirmed, onInactive) => {
  handleEndChat(
    confirmed,
    onInactive,
    convId,
    cleanUp,
    chatSDK,
    surveyUrl,
    chatMessages,
    salesLocation,
    showChatWidget,
    emailTranscript,
    initiatingSurvey,
    confirmCloseChat,
    inactivityTimeout,
    showPostChatSurvey,
    confirmDecisionState,
    preChatSurveyResponses,
    CHAT_CHANNEL.EXTERNAL
  );
};

const cancelEndChat = () => {
  handleCancelEndChat(
    endChat,
    showChatWidget,
    emailTranscript,
    confirmCloseChat,
    inactivityTimeout,
    showPostChatSurvey,
    confirmDecisionState,
    preChatSurveyResponses
  );
};

const cleanUp = () => {
  handleCleanup(
    chatId,
    agentName,
    surveyUrl,
    clearSurvey,
    closedExtra,
    chatMessages,
    labelToIdMap,
    messageToSend,
    salesLocation,
    offlineTimeout,
    sendingMessage,
    showChatWidget,
    emailTranscript,
    sendMessageError,
    confirmCloseChat,
    initiatingSurvey,
    inactivityTimeout,
    isExistingCustomer,
    showPostChatSurvey,
    showQualtricsSurvey,
    confirmDecisionState,
    preChatSurveyResponses,
    CHAT_CHANNEL.EXTERNAL,
    props.showChatBot,
    conversationEndByZoey
  );
};

const closeChatWindow = async (endChat) => {
  await handleCloseChatWindow(
    convId,
    chatSDK,
    hashKey,
    cleanUp,
    startChat,
    showChatWidget,
    preChatQuestions,
    endChat,
    fingerPrint,
    chatMessages,
    salesLocation,
    isNewCustomer,
    channelOptions,
    transcriptSent,
    endChatLoading,
    isZiplyCustomer,
    confirmCloseChat,
    clearSessionStore,
    showPostChatSurvey,
    showQualtricsSurvey,
    foundCustomerSession,
    preChatSurveyResponses,
    CHAT_CHANNEL.EXTERNAL
  );
};

const startNewChat = async (endChat = true) => {
  handleStartNewChat(
    cleanUp,
    hashKey,
    startChat,
    toggleChat,
    fingerPrint,
    isNewCustomer,
    isZiplyCustomer,
    preChatQuestions,
    foundCustomerSession,
    salesLocation,
    showChatWidget,
    preChatSurveyResponses,
    CHAT_CHANNEL.EXTERNAL,
    props.showChatBot
  );
};

const downloadFile = async (fileMetadata) => {
  handleFileDownlaod(
    convId,
    chatSDK,
    fileMetadata,
    channelOptions,
    CHAT_CHANNEL.EXTERNAL,
    preChatSurveyResponses
  );
};

const processUpload = async (evt) => {
  handleFileUpload(
    evt,
    convId,
    chatSDK,
    chatMessages,
    sendingMessage,
    sendMessageError,
    channelOptions,
    CHAT_CHANNEL.EXTERNAL,
    preChatSurveyResponses
  );
};

const handleRequestEmailTranscript = () => {
  requestEmailTranscript(
    convId,
    chatSDK,
    chatMessages,
    channelOptions,
    transcriptSent,
    emailTranscript,
    confirmCloseChat,
    transcriptSending,
    showPostChatSurvey,
    showQualtricsSurvey,
    transcriptSentFailed,
    CHAT_CHANNEL.EXTERNAL,
    preChatSurveyResponses
  );
};

const emailKeyUp = () => {
  transcriptSentFailed.value = "";
};

window.triggerOmniChat = (secretKey) => {
  if (secretKey === "D582223DCFE61") {
    showChatWidget.value = true;
    console.warn("CHAT INITIATED");
  }
};

window.hasActiveSession = () => {
  return Boolean(chatSDK?.chatToken?.chatId);
};

const completeLoginFlow = async (customerSession) => {
  try {
    preChatSurveyResponses.value.channel_id = "Sales";
    preChatSurveyResponses.value.name =
      customerSession?.firstName + " " + customerSession?.lastName;
    preChatSurveyResponses.value.email = customerSession?.email;
    preChatSurveyResponses.value.usi = customerSession?.usi;
    preChatSurveyResponses.value.uuidt = customerSession?.uuidt;
    preChatSurveyResponses.value.uuidr = customerSession?.uuidr;
    preChatSurveyResponses.value.phone = fillingPhone(customerSession?.phone);
  } catch (e) {
    console.log(e);
  }
  const { preChatResponse, chatContext } = buildDataToStartChat(
    preChatQuestions.value,
    preChatSurveyResponses.value
  );
  startChat(preChatResponse, chatContext);
};

if (isMobileApp) {
  toggleChat();
}

window.ononline = async () => {
  onOnline(
    convId,
    chatSDK,
    endChat,
    chatMessages,
    showChatWidget,
    sendingMessage,
    offlineTimeout,
    channelOptions,
    emailTranscript,
    sendMessageError,
    confirmCloseChat,
    inactivityTimeout,
    showPostChatSurvey,
    preChatSurveyResponses,
    CHAT_CHANNEL.EXTERNAL
  );
};

window.onoffline = async () => {
  onOffline(sendingMessage, sendMessageError, offlineTimeout);
};
</script>

<template>
  <div
    id="chatWrapper"
    class="chatWrapper"
    v-if="salesAgentsAvailable && allowChat"
  >
    <ChatActivator
      :is-loading="isLoading"
      :show-chat-widget="showChatWidget"
      :chat-action="chatAction"
    />
    <div
      id="chatContainer"
      class="!zf-z-[1100] zf-fixed zf-bg-white zf-rounded zf-shadow zf-overflow-hidden !zf-w-12/12 sm:zf-w-[400px] !sm:zf-h-[546px] !zf-right-6 !zf-bottom-[100px] !sm:zf-right-16 !sm:zf-bottom-[126px] !zf-border !zf-border-white"
      v-if="showChatWidget"
    >
      <div
        id="mobHeader"
        class="!zf-h-[9%] !zf-bg-ziply-blue !zf-text-gray-100 !zf-flex !zf-justify-between !zf-w-full !zf-items-center !zf-px-4 !zf-rounded-t"
      >
        <ChatHeader
          :confirm-decision-state="confirmDecisionState"
          :toggle-chat="toggleChat"
          :confirm-close-chat="confirmCloseChat"
          :initiating-survey="initiatingSurvey"
          :end-chat="endChat"
          :show-qualtrics-survey="showQualtricsSurvey"
          :show-post-chat-survey="showPostChatSurvey"
          :close-chat-window="closeChatWindow"
          :is-external="true"
        />
      </div>
      <div
        id="MobConvoBody"
        class="!zf-h-[91.2%] !zf-w-full !zf-text-[#3c3c3c] !zf-text-justify zf-overflow-hidden"
      >
        <div
          class="!zf-h-full"
          id="mobChatBody"
          v-if="chatMessages?.length === 0"
        >
          <PreChatScreens
            :is-loading="isLoading"
            :is-ziply-customer="isZiplyCustomer"
            :found-customer-session="foundCustomerSession"
            :is-existing-customer="isExistingCustomer"
            :new-customer="newCustomer"
            :complete-login-flow="completeLoginFlow"
            :hash-key="hashKey"
            :is-new-customer="isNewCustomer"
            :existing-customer-flow="existingCustomerFlow"
            :initialize-error="initializeError"
            :channel-options="channelOptions"
            :start-chat="startChat"
            :pre-chat-survey-responses="preChatSurveyResponses"
            :pre-chat-questions="preChatQuestions"
            :is-internal="isInternal"
            :showChatBot="showChatBot"
            :is-external="true"
          />
        </div>
        <template v-else>
          <div
            class="!zf-flex zf-flex-col !zf-gap-2 !zf-h-full"
            v-if="!confirmCloseChat"
          >
            <div v-if="isMobileApp" id="mobBtnWrapper">
              <p id="mobCloseChatButton" @click="endChat()">END CHAT</p>
            </div>
            <AllMessages
              :chat-messages="chatMessages"
              :inputDisabledByAdaptiveCard="inputDisabledByAdaptiveCard"
              :send-message="sendMessage"
              :download-file="downloadFile"
              :pre-chat-survey-responses="preChatSurveyResponses"
            />
            <ChatFooter
              :is-typing="isTyping"
              :chat-bot-typing="chatBotTyping"
              :send-message-error="sendMessageError"
              :disable-chat-on-adaptive-card="disableChatOnAdaptiveCard"
              :system-message="systemMessage"
              :message-to-send="messageToSend"
              @update:message-to-send="(newValue) => (messageToSend = newValue)"
              :process-upload="processUpload"
              :sending-message="sendingMessage"
              :send-message="sendMessage"
              :customer-typing="customerTyping"
            />
          </div>
          <div
            class="!zf-w-full !zf-h-full !zf-bg-white"
            v-if="confirmCloseChat"
          >
            <div
              v-if="!showPostChatSurvey"
              class="zf-text-center zf-flex zf-flex-col zf-items-center zf-justify-center zf-h-full zf-gap-4 zf-px-6"
            >
              <ConfirmEndChat
                :end-chat-loading="endChatLoading"
                :inactivity-timeout="inactivityTimeout"
                :request-email-transcript="handleRequestEmailTranscript"
                :email-transcript="emailTranscript"
                @update:email-transcript="
                  (newValue) => (emailTranscript = newValue)
                "
                :emailKeyUp="emailKeyUp"
                :transcript-sent-failed="transcriptSentFailed"
                :transcript-sending="transcriptSending"
                :transcript-sent="transcriptSent"
                @closeChatWindow="closeChatWindow"
                @cancelEndChat="cancelEndChat"
                :conversationEndByZoey="conversationEndByZoey"
              />
            </div>
            <div v-else class="zf-h-full">
              <ChatEnd
                :email-transcript="emailTranscript"
                :show-qualtrics-survey="showQualtricsSurvey"
                @update:show-qualtrics-survey="
                  (newValue) => (showQualtricsSurvey = newValue)
                "
                :transcript-sent="transcriptSent"
                :start-new-chat="startNewChat"
                :survey-url="surveyUrl"
                :is-internal="false"
                :sales-location="salesLocation"
                :sales-agents-available="salesAgentsAvailable"
                :channel-options="channelOptions"
                :pre-chat-survey-responses="preChatSurveyResponses"
                :conv-id="convId"
                :external="true"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
